import { SVGProps } from 'react';

interface IconProps extends SVGProps<SVGSVGElement> {
  weight?: 'fill' | 'regular';
}

export const SentimentVerySatisfied = ({
  width = 24,
  height = 24,
  weight = 'regular',
}: IconProps) => {
  if (weight === 'fill') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M480-260q68 0 123.5-38.5T684-400H276q25 63 80.5 101.5T480-260ZM312-520l44-42 42 42 42-42-84-86-86 86 42 42Zm250 0 42-42 44 42 42-42-86-86-84 86 42 42ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
    >
      <mask
        id="mask0_2753_199953"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={width}
        height={height}
      >
        <rect x="0.799927" width={width} height={height} fill="currentColor" />
      </mask>
      <g mask="url(#mask0_2753_199953)">
        <path
          d="M12.7999 17.1923C13.8434 17.1923 14.7973 16.9003 15.6614 16.3163C16.5256 15.7323 17.1628 14.9602 17.5729 14H8.02693C8.43709 14.9602 9.07426 15.7323 9.93843 16.3163C10.8026 16.9003 11.7564 17.1923 12.7999 17.1923ZM8.59993 10.7885L9.69993 9.71925L10.7692 10.7885L11.6077 9.95L9.69993 8.0115L7.76143 9.95L8.59993 10.7885ZM14.8307 10.7885L15.8999 9.71925L16.9999 10.7885L17.8384 9.95L15.8999 8.0115L13.9922 9.95L14.8307 10.7885ZM12.8017 21.5C11.4877 21.5 10.2526 21.2507 9.09643 20.752C7.94026 20.2533 6.93459 19.5766 6.07943 18.7218C5.22426 17.8669 4.54718 16.8617 4.04818 15.706C3.54934 14.5503 3.29993 13.3156 3.29993 12.0017C3.29993 10.6877 3.54926 9.45267 4.04793 8.2965C4.54659 7.14033 5.22334 6.13467 6.07818 5.2795C6.93301 4.42433 7.93826 3.74725 9.09393 3.24825C10.2496 2.74942 11.4843 2.5 12.7982 2.5C14.1122 2.5 15.3473 2.74933 16.5034 3.248C17.6596 3.74667 18.6653 4.42342 19.5204 5.27825C20.3756 6.13308 21.0527 7.13833 21.5517 8.294C22.0505 9.44967 22.2999 10.6844 22.2999 11.9983C22.2999 13.3123 22.0506 14.5473 21.5519 15.7035C21.0533 16.8597 20.3765 17.8653 19.5217 18.7205C18.6668 19.5757 17.6616 20.2528 16.5059 20.7518C15.3503 21.2506 14.1155 21.5 12.8017 21.5ZM12.7999 20C15.0333 20 16.9249 19.225 18.4749 17.675C20.0249 16.125 20.7999 14.2333 20.7999 12C20.7999 9.76667 20.0249 7.875 18.4749 6.325C16.9249 4.775 15.0333 4 12.7999 4C10.5666 4 8.67493 4.775 7.12493 6.325C5.57493 7.875 4.79993 9.76667 4.79993 12C4.79993 14.2333 5.57493 16.125 7.12493 17.675C8.67493 19.225 10.5666 20 12.7999 20Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
