import {
  SuperAgentModels,
  WebSearchTypes,
  WebSearchTypesLabels,
  NinjaSubscriptionPlan,
} from 'src/types';
import { Image, Video } from '@phosphor-icons/react';

export const webSearchTypesList = [
  {
    value: WebSearchTypes.VIDEO_SEARCH,
    label: WebSearchTypesLabels.VIDEO_SEARCH,
    icon: Video,
  },
  {
    value: WebSearchTypes.IMAGE_SEARCH,
    label: WebSearchTypesLabels.IMAGE_SEARCH,
    icon: Image,
  },
];

export enum ExternalModelProvider {
  AMAZON_BEDROCK = 'amazon_bedrock',
  GOOGLE = 'google',
  OPENAI = 'openai',
}

export enum ImageGeneratorsModelProvider {
  BEDROCK = 'bedrock',
  OPENAI = 'openai',
  NINJA_PIX = 'sagemaker',
  NINJA_PIX_PRO = 'anywhere',
}

// the list of image generator models which will have "NEW" label
export const newImageGeneratorModels: string[] = [
  'openai/openai/dall-e-3',
  'bedrock/amazon/amazon.titan-image-generator-v1',
  'bedrock/amazon/stability.stable-diffusion-xl-v1',
];

// TODO(olha): it's a hard workaround for rendering labels (temporary solution)
export const availableForProLLMs: string[] = [
  'openai/openai/gpt-4o-mini',
  'bedrock/anthropic/anthropic.claude-3-5-haiku-20241022-v1:0',
  'gemini/google/gemini-1.5-flash',
  'bedrock/amazon/us.amazon.nova-micro-v1:0',
  'bedrock/amazon/us.amazon.nova-lite-v1:0',
];

export const NINJA_AGENT_AS_EXTERNAL_MODEL = 'alias/ninjatech/ninja-agent';
export const SUPER_AGENT_AS_EXTERNAL_MODEL = 'alias/ninjatech/super-agent';
export const NINJA_AGENT_TITLE = 'Ninja Agent';

// (olha): WIP we are moving to reading all data from BE using getDefaultModelIconName and getTextModelDisplayName
export const externalModelsSet: Record<
  string,
  {
    icon_name: string;
    display_name: string;
    provider?: ExternalModelProvider;
    hide_icon_in_settings?: boolean;
  }
> = {
  'bedrock/amazon/amazon.titan-text-express-v1': {
    icon_name: 'awsLogo',
    display_name: 'Titan Text G1 - Express',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
    hide_icon_in_settings: true,
  },
  // deprecated
  'bedrock/anthropic/anthropic.claude-3-haiku-20240307-v1:0': {
    icon_name: 'claudeLogo',
    display_name: 'Claude 3 Haiku',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  // deprecated
  'bedrock/anthropic/anthropic.claude-3-5-sonnet-20240620-v1:0': {
    icon_name: 'claudeLogo',
    display_name: 'Claude 3.5 Sonnet',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  // deprecated
  'bedrock/ai21/ai21.j2-mid-v1': {
    icon_name: 'jurassicLogo',
    display_name: 'Jurassic-2 Mid',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  // deprecated
  'bedrock/ai21/ai21.j2-ultra-v1': {
    icon_name: 'jurassicLogo',
    display_name: 'Jurassic-2 Ultra',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  // deprecated
  'bedrock/anthropic/anthropic.claude-3-sonnet-20240229-v1:0': {
    icon_name: 'claudeLogo',
    display_name: 'Claude 3.5 Sonnet',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/amazon/amazon.titan-text-lite-v1': {
    icon_name: 'awsLogo',
    display_name: 'Titan Text G1 - Lite',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
    hide_icon_in_settings: true,
  },
  'bedrock/amazon/amazon.titan-text-premier-v1:0': {
    icon_name: 'awsLogo',
    display_name: 'Amazon Titan Text Premier',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
    hide_icon_in_settings: true,
  },
  'bedrock/anthropic/anthropic.claude-3-5-haiku-20241022-v1:0': {
    icon_name: 'claudeLogo',
    display_name: 'Claude 3.5 Haiku',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/anthropic/anthropic.claude-3-opus-20240229-v1:0': {
    icon_name: 'claudeLogo',
    display_name: 'Claude 3 Opus',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/anthropic/anthropic.claude-3-5-sonnet-20241022-v2:0': {
    icon_name: 'claudeLogo',
    display_name: 'Claude 3.5 Sonnet',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/cohere/cohere.command-r-v1:0': {
    icon_name: 'cohereLogo',
    display_name: 'Command R',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/cohere/cohere.command-r-plus-v1:0': {
    icon_name: 'cohereLogo',
    display_name: 'Command R+',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/mistral/mistral.mistral-small-2402-v1:0': {
    icon_name: 'mistralLogo',
    display_name: 'Mistral Small',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  'bedrock/mistral/mistral.mistral-large-2402-v1:0': {
    icon_name: 'mistralLogo',
    display_name: 'Mistral Large',
    provider: ExternalModelProvider.AMAZON_BEDROCK,
  },
  // @deprecated
  'gemini/google/gemini-1.0-pro': {
    icon_name: 'googleGeminiProLogo',
    display_name: 'Gemini 1.0 Pro',
    provider: ExternalModelProvider.GOOGLE,
    hide_icon_in_settings: true,
  },
  'gemini/google/gemini-1.5-pro': {
    icon_name: 'googleGeminiProLogo',
    display_name: 'Gemini 1.5 Pro',
    provider: ExternalModelProvider.GOOGLE,
    hide_icon_in_settings: true,
  },
  'gemini/google/gemini-1.5-flash': {
    icon_name: 'googleGeminiProLogo',
    display_name: 'Gemini 1.5 Flash',
    provider: ExternalModelProvider.GOOGLE,
    hide_icon_in_settings: true,
  },
  // @deprecated
  'openai/openai/gpt-4-0125-preview': {
    icon_name: 'chatGPTLogo',
    display_name: 'GPT-4 Turbo',
    provider: ExternalModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  // @deprecated
  'openai/openai/gpt-4o-2024-05-13': {
    icon_name: 'chatGPTLogo',
    display_name: 'GPT-4o',
    provider: ExternalModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  // @deprecated
  'openai/openai/gpt-3.5-turbo-1106': {
    icon_name: 'chatGPTLogo',
    display_name: 'GPT-3.5 Turbo',
    provider: ExternalModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  'openai/openai/gpt-4o': {
    icon_name: 'chatGPTLogo',
    display_name: 'GPT-4o',
    provider: ExternalModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  'openai/openai/gpt-4o-mini': {
    icon_name: 'chatGPTLogo',
    display_name: 'GPT-4o Mini',
    provider: ExternalModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  [SUPER_AGENT_AS_EXTERNAL_MODEL]: {
    icon_name: 'ninjaPixProLogo',
    display_name: 'Ninja SuperAgent',
    provider: 'ninjatech' as ExternalModelProvider,
    hide_icon_in_settings: true,
  },
  'alias/ninjatech/super-agent:turbo': {
    icon_name: 'ninjaPixProLogo',
    display_name: 'SuperAgent Turbo',
    provider: 'ninjatech' as ExternalModelProvider,
    hide_icon_in_settings: true,
  },
  'alias/ninjatech/super-agent:nexus': {
    icon_name: 'ninjaPixProLogo',
    display_name: 'SuperAgent Nexus',
    provider: 'ninjatech' as ExternalModelProvider,
    hide_icon_in_settings: true,
  },
  'alias/ninjatech/super-agent:apex': {
    icon_name: 'ninjaPixProLogo',
    display_name: 'SuperAgent Apex',
    provider: 'ninjatech' as ExternalModelProvider,
    hide_icon_in_settings: true,
  },
};

export enum ImageGenerationModels {
  PIX = 'alias/ninjatech/ninja-pix',
  PIX_PRO = 'alias/ninjatech/ninja-pix-pro',
}

export enum ImageGenerationModelOptions {
  PIX = 'alias/ninjatech/ninja-pix',
  PIX_PRO = 'alias/ninjatech/ninja-pix-pro',
  BOTH = 'both',
}

export const AvailableImageGenerationModels = [
  ImageGenerationModels.PIX,
  ImageGenerationModels.PIX_PRO,
];

// (olha): WIP we are moving to reading all data from BE using getDefaultModelIconName and getImageModelDisplayName
export const imageGeneratorsModelsSet: Record<
  string,
  {
    display_name: string;
    icon_name?: string;
    provider: ImageGeneratorsModelProvider;
    hide_icon_in_settings?: boolean;
  }
> = {
  'openai/openai/dall-e-3': {
    icon_name: 'chatGPTLogo',
    display_name: 'DALL-E 3',
    provider: ImageGeneratorsModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  // @deprecated
  'openai/openai/dall-e-2': {
    icon_name: 'chatGPTLogo',
    display_name: 'DALL-E 2',
    provider: ImageGeneratorsModelProvider.OPENAI,
    hide_icon_in_settings: true,
  },
  // @deprecated
  'bedrock/amazon/amazon.titan-image-generator-v1': {
    display_name: 'Amazon Titan Image Generator',
    provider: ImageGeneratorsModelProvider.BEDROCK,
    icon_name: 'awsLogo',
    hide_icon_in_settings: true,
  },
  'bedrock/amazon/amazon.titan-image-generator-v2:0': {
    display_name: 'Amazon Titan Image Generator',
    provider: ImageGeneratorsModelProvider.BEDROCK,
    icon_name: 'awsLogo',
    hide_icon_in_settings: true,
  },
  'bedrock/amazon/stability.stable-diffusion-xl-v1': {
    display_name: 'Stable Diffusion XL 1.0',
    provider: ImageGeneratorsModelProvider.BEDROCK,
    icon_name: 'bedrockStableLogo',
  },
  [ImageGenerationModels.PIX]: {
    display_name: 'Ninja PixArt',
    provider: ImageGeneratorsModelProvider.NINJA_PIX,
    icon_name: 'ninjaOutlined',
  },
  [ImageGenerationModels.PIX_PRO]: {
    display_name: 'Ninja Flux',
    provider: ImageGeneratorsModelProvider.NINJA_PIX_PRO,
    icon_name: 'ninjaPixProLogo',
  },
};

export const imageStyleOptions = [
  { value: 'none', label: '(No Style)' },
  { value: 'cinematic', label: 'Cinematic' },
  { value: 'photographic', label: 'Photographic' },
  { value: 'anime', label: 'Anime' },
  { value: 'manga', label: 'Manga' },
  { value: 'digital_art', label: 'Digital' },
  { value: 'pixel_art', label: 'Pixel Art' },
  { value: 'fantasy_art', label: 'Fantasy' },
  { value: 'neonpunk', label: 'Neonpunk' },
  { value: 'model_3d', label: '3D' },
];

export const imageModelOptions = [
  {
    value: ImageGenerationModelOptions.BOTH,
    label: 'Ninja PixArt & Ninja Flux',
  },
  {
    value: ImageGenerationModelOptions.PIX,
    label: 'Ninja PixArt',
  },
  {
    value: ImageGenerationModelOptions.PIX_PRO,
    label: 'Ninja Flux',
  },
];

export const imageNumberOptions = [
  {
    value: 1,
    label: '1 Image',
  },
  {
    value: 2,
    label: '2 Images',
  },
  {
    value: 3,
    label: '3 Images',
  },
  {
    value: 4,
    label: '4 Images',
  },
];

export const superAgentModelSet: Record<
  SuperAgentModels,
  { title: string; description: string; isCapableFrom?: NinjaSubscriptionPlan }
> = {
  [SuperAgentModels.TURBO]: {
    title: 'Turbo',
    description: 'Fast with mix of internal models',
  },

  [SuperAgentModels.NEXUS]: {
    title: 'Nexus',
    description: 'Medium speed with mid-sized external models',
    isCapableFrom: NinjaSubscriptionPlan.PRO,
  },

  [SuperAgentModels.APEX]: {
    title: 'Apex',
    description: 'Slow with highest quality using largest external models',
    isCapableFrom: NinjaSubscriptionPlan.ULTRA,
  },
};
