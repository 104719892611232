import { useMemo, useState } from 'react';
import { ShareFat } from '@phosphor-icons/react';
import {
  useLazyCreateMessageSharedLinkQuery,
  useLazyCreateVideoSharedLinkQuery,
} from 'src/store/services/sharingApi';
import { useSession, useVisible } from 'src/hooks';
import { AvatarVideoPresetName } from 'src/types/models/AvatarVideoPresetName';
import { AvatarVideos } from 'src/types/models/AvatarVideos';
import { ShareModal } from './components/ShareModal';
import { sendGTMEvent } from 'src/utils';
import { GTMEvent } from 'src/types';

type MessageOrVideo =
  | {
      messageId: string;
      videoData?: never;
      videoResolution?: never;
    }
  | {
      messageId?: never;
      videoData: AvatarVideos;
      videoResolution?: AvatarVideoPresetName;
    };

type ShareButtonProps = MessageOrVideo & {
  buttonIconSize?: number;
  withTitle?: boolean;
  disabled?: boolean;
};

export const ShareButton = ({
  messageId,
  buttonIconSize = 24,
  withTitle,
  videoData,
  videoResolution,
  disabled,
}: ShareButtonProps) => {
  const { isVisible, onVisibilityRemove, onVisibilitySet } = useVisible();

  const { appUser } = useSession();
  const [
    createMessageLink,
    { data: shareMessageLink, isLoading: isMessageLinkLoading },
  ] = useLazyCreateMessageSharedLinkQuery();
  const [createVideoLink] = useLazyCreateVideoSharedLinkQuery();

  const [shareVideoLink, setShareVideoLink] = useState<string>();
  const [isVideoLinkLoading, setIsVideoLinkLoading] = useState<boolean>(false);

  const isStringResolution = (data: unknown): data is AvatarVideoPresetName => {
    return !!data && typeof data === 'string';
  };

  const currentResolutions:
    | AvatarVideoPresetName
    | AvatarVideoPresetName[]
    | null = useMemo(() => {
    if (!videoData) {
      return null;
    }

    if (videoResolution) {
      return videoResolution;
    }

    const allResolutions: AvatarVideoPresetName[] =
      videoData.urls
        ?.map(({ name }) => name)
        .filter((item) => item !== AvatarVideoPresetName.ALL) || [];

    if (allResolutions.length === 0) {
      return null;
    }

    if (allResolutions.length === 1) {
      return allResolutions[0];
    }

    return allResolutions;
  }, [videoResolution, videoData]);

  const hasResolutionForm = useMemo(
    () =>
      !!currentResolutions &&
      !!!isStringResolution(currentResolutions) &&
      !!videoData,
    [currentResolutions, videoData],
  );

  const handleClose = () => {
    onVisibilityRemove();
    if (shareVideoLink) {
      setShareVideoLink(undefined);
    }
  };

  const handleCreateVideoLinkByResolution = async (
    value: AvatarVideoPresetName,
  ) => {
    if (videoData?.video_id) {
      setIsVideoLinkLoading(true);
      const result = await createVideoLink({
        videoId: videoData.video_id,
        videoResolution: value,
      });
      setShareVideoLink(result.data);
      setIsVideoLinkLoading(false);
    }
  };

  const handleShareButtonClick = async () => {
    sendGTMEvent(GTMEvent.SHARE_BUTTON);
    onVisibilitySet();

    if (messageId) {
      return await createMessageLink({
        userId: appUser.user_id,
        messageId: messageId,
      });
    }

    if (videoData?.video_id && isStringResolution(currentResolutions)) {
      setIsVideoLinkLoading(true);
      const result = await createVideoLink({
        videoId: videoData.video_id,
        videoResolution: currentResolutions,
      });
      setShareVideoLink(result.data);
      return setIsVideoLinkLoading(false);
    }
  };

  return (
    <>
      <button
        type="button"
        disabled={disabled || isMessageLinkLoading || isVideoLinkLoading}
        onClick={handleShareButtonClick}
        data-e2e="share-button"
      >
        <ShareFat size={buttonIconSize} weight="fill" />

        {withTitle && <span>Share</span>}
      </button>

      <ShareModal
        isOpen={isVisible}
        isLoading={isMessageLinkLoading || isVideoLinkLoading}
        shareLink={shareMessageLink || shareVideoLink}
        hasResolutionForm={hasResolutionForm}
        videoData={videoData}
        onClose={handleClose}
        onSetResolution={handleCreateVideoLinkByResolution}
      />
    </>
  );
};
