import { useState, useCallback } from 'react';

export const useVisible = (value?: boolean) => {
  const [isVisible, setIsVisible] = useState<boolean>(value ?? false);

  const onVisibilitySet = useCallback(() => setIsVisible(true), []);
  const onVisibilityRemove = useCallback(() => setIsVisible(false), []);
  const onVisibilityToggle = useCallback(
    () => setIsVisible((state) => !state),
    [],
  );

  return {
    isVisible,
    onVisibilitySet,
    onVisibilityRemove,
    onVisibilityToggle,
  };
};
