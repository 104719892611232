import { forwardRef, ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  shape?: 'regular' | 'round';
  color?: 'primary' | 'secondary' | 'transparent' | 'link';
  className?: string;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { shape = 'regular', color = 'primary', className, children, ...props },
    ref,
  ) => (
    <button
      ref={ref}
      {...props}
      className={classNames(
        styles.root,
        {
          [styles.regular]: shape === 'regular',
          [styles.round]: shape === 'round',
          [styles.primary]: color === 'primary',
          [styles.secondary]: color === 'secondary',
          [styles.transparent]: color === 'transparent',
          [styles.link]: color === 'link',
        },
        className,
      )}
    >
      {children}
    </button>
  ),
);
