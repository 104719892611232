import { ChangeEvent } from 'react';
import classNames from 'classnames';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';
import styles from './TextareaSimple.module.scss';

interface TextareaSimpleProps extends Omit<TextareaAutosizeProps, 'onChange'> {
  value: string;
  isDisabled?: boolean;
  className?: string;
  onChange: (value: string) => void;
}

export const TextareaSimple = ({
  value,
  isDisabled,
  className,
  onChange,
  ...rest
}: TextareaSimpleProps) => {
  const handleChange = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
    if (!isDisabled) {
      onChange(target.value);
    }
  };

  return (
    <TextareaAutosize
      value={value}
      disabled={isDisabled}
      className={classNames(styles.root, className)}
      onChange={handleChange}
      {...rest}
    />
  );
};
