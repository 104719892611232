import {
  ChitChatCard,
  Message,
  MessageType,
  ResearchTaskCreationCard,
  SchedulerCreationCard,
  ImageCard,
} from 'src/types';
import { ThreadMessageContent } from '../../../../../components/FlatAppearance/components/ThreadMessageContent';
import { ThreadScheduleCard } from '../../../../../components/FlatAppearance/components/ThreadScheduleCard';
import { DebugMessageWrapper } from 'src/pages/ManageTasksChatPage/components/TaskScratchpad/components/DebugMessageWrapper';
import { ThreadErrorMessageContent } from 'src/components/FlatAppearance/components/ThreadErrorMessageContent';
import { ErrorPayload } from 'src/types/models/ErrorPayload';
import { ThreadImageGenerationCard } from 'src/components/FlatAppearance/components/ThreadImageGenerationCard';
import { ThreadResearchCard } from 'src/v2/components/ThreadMessage/components/ThreadResearchCard';
import { ThreadChitChatCard } from 'src/v2/components/ThreadMessage/components/ThreadChitChatCard';
import { FeedbackRating } from 'src/v2/components/FeedbackRating';

interface ThreadMessageBodyProps {
  message: Message;
  isFinalMessageChunk?: boolean;
}

export const ThreadMessageBody = ({
  message,
  isFinalMessageChunk,
}: ThreadMessageBodyProps) => {
  const { message_type, payload, message_id } = message;

  const shouldStreaming = !isFinalMessageChunk;

  const getCardToDisplay = () => {
    if (!payload) {
      return null;
    }

    switch (message_type) {
      case MessageType.RESEARCH_TASK_CREATION_CARD:
        return (
          <ThreadResearchCard
            messageId={message_id}
            researchCard={(payload as ResearchTaskCreationCard).research_card}
            // TODO(olha): this is a workaround! needs to change condition and moves to child components to avoid chain of props!!!
            isStreaming={shouldStreaming}
          />
        );
      case MessageType.SCHEDULER_TASK_CREATION_CARD:
        return (
          <ThreadScheduleCard
            messageId={message_id}
            calendarCard={(payload as SchedulerCreationCard).calendar_card}
            details={(payload as SchedulerCreationCard).details}
            //TODO(olha): add prop from BE
            summary=""
          />
        );
      // TODO(olha): should be deprecated
      case MessageType.CODE_TASK_CREATION_CARD:
        return (
          <ThreadChitChatCard
            messageId={message_id}
            chitChatCard={payload as ChitChatCard}
            isStreaming={shouldStreaming}
          />
        );
      case MessageType.ERROR_MESSAGE:
        return (
          <ThreadErrorMessageContent
            content={message.content}
            errorCode={(payload as ErrorPayload).error_code}
            unsupportedSkills={(payload as ErrorPayload).unsupported_skills}
          />
        );
      case MessageType.CHAT_CARD:
        return (
          <ThreadChitChatCard
            messageId={message_id}
            chitChatCard={payload as ChitChatCard}
            isStreaming={shouldStreaming}
          />
        );
      case MessageType.IMAGE_CARD:
        return (
          <ThreadImageGenerationCard
            messageId={message_id}
            imageCard={payload as ImageCard}
            messageOriginalQuery={message.original_query}
            messageCleanQuery={message.clean_query}
            isStreaming={shouldStreaming}
          />
        );
      default:
        return (
          <>
            <ThreadMessageContent
              content={message.content}
              isStreaming={shouldStreaming}
            />

            <FeedbackRating
              messageId={message_id}
              isStreaming={shouldStreaming}
              operationType="message-level"
            />
          </>
        );
    }
  };

  return (
    <DebugMessageWrapper message={message}>
      {getCardToDisplay()}
    </DebugMessageWrapper>
  );
};
