import { useMemo, useRef } from 'react';
import { ControlledMenu } from '@szhsin/react-menu';
import { CaretDown } from '@phosphor-icons/react';
import {
  ImageGenSettingsDimensionGroups,
  ImageGenSettingsDimensionGroupTypes,
  ImageGenSettingsDimensionOptionTypes,
  ImageGenSettingsResolutions,
  ImageGenSettingsSelectedDimension,
} from 'src/types';
import styles from './DimensionMoreSelector.module.scss';
import { SVG_SIZE_S } from 'src/constants';
import classNames from 'classnames';
import { useVisible, useBreakpoint } from 'src/hooks';
import { DimensionMoreOptions } from '../DimensionMoreOptions';
import { BottomDrawer } from 'src/components/BottomDrawer';

type SelectedValue = {
  group: ImageGenSettingsDimensionGroupTypes;
  option: ImageGenSettingsDimensionOptionTypes;
};

interface DimensionMoreSelectorProps {
  onSelect: ({ group, option }: SelectedValue) => void;
  isSelected: boolean;
  selectedData: ImageGenSettingsSelectedDimension;
  dimensionGroups: ImageGenSettingsDimensionGroups;
}

export const DimensionMoreSelector = ({
  selectedData,
  dimensionGroups,
  onSelect,
}: DimensionMoreSelectorProps) => {
  const { isVisible, onVisibilityRemove, onVisibilityToggle } = useVisible();

  const { isMobileOrTablet } = useBreakpoint();

  const buttonRef = useRef(null);

  const isPrimaryGroupSelected =
    selectedData.group === ImageGenSettingsDimensionGroupTypes.PRIMARY;

  // TODO(olha): use the value from BE
  const handleDisablingCheck = () => {
    return Object.entries(dimensionGroups)
      .filter(([key]) => key !== ImageGenSettingsDimensionGroupTypes.PRIMARY)
      .every(([_, group]) =>
        Object.values(group).every(({ resolutions }) =>
          resolutions && Object.values(resolutions).length > 0
            ? Object.values(resolutions).every(({ is_capable }) => !is_capable)
            : true,
        ),
      );
  };

  const toggleButtonContent = useMemo(() => {
    if (isPrimaryGroupSelected) {
      return <span>More</span>;
    }

    const { option, group } = selectedData;

    const currentGroup = dimensionGroups[group] || {};
    const { name, aspect_ratio } =
      (
        currentGroup as unknown as Record<
          ImageGenSettingsDimensionOptionTypes,
          ImageGenSettingsResolutions
        >
      )[option] || {};

    return (
      <div className={styles.selectedToggleContent}>
        <span>{name}</span>
        <span>{aspect_ratio}</span>
      </div>
    );
  }, [isPrimaryGroupSelected, selectedData, dimensionGroups]);

  const handleItemClick = (value: SelectedValue) => {
    onVisibilityRemove();
    onSelect(value);
  };

  return (
    <div className={styles.root}>
      <button
        ref={buttonRef}
        className={classNames(styles.toggleButton, {
          [styles.active]: !isPrimaryGroupSelected,
          [styles.disabled]: handleDisablingCheck(),
        })}
        disabled={handleDisablingCheck()}
        onClick={onVisibilityToggle}
      >
        {toggleButtonContent}
        <CaretDown size={SVG_SIZE_S} />
      </button>

      {isMobileOrTablet ? (
        <BottomDrawer isOpen={isVisible} onClose={onVisibilityRemove}>
          <DimensionMoreOptions
            dimensionGroups={dimensionGroups}
            selectedData={selectedData}
            onClick={handleItemClick}
          />
        </BottomDrawer>
      ) : (
        <ControlledMenu
          gap={8}
          menuClassName={styles.menu}
          state={isVisible ? 'open' : 'closed'}
          anchorRef={buttonRef}
          onClose={onVisibilityRemove}
          transition
        >
          <DimensionMoreOptions
            dimensionGroups={dimensionGroups}
            selectedData={selectedData}
            onClick={handleItemClick}
          />
        </ControlledMenu>
      )}
    </div>
  );
};
