import { useVisible, useSession, useGoogleResourcesAccess } from 'src/hooks';
import { Button } from 'src/components/Button';
import { AccessModal } from 'src/pages/ManageTasksChatPage/components/AccessAndDataSettings/components/AccessModal';
import { contactsApi, ContactsTags } from 'src/store/services';
import { useAppDispatch } from 'src/store';
import { CloudSlash } from '@phosphor-icons/react';
import styles from 'src/pages/ManageAccountPage/components/General/General.module.scss';

export const DisconnectAccess = () => {
  const dispatch = useAppDispatch();

  const { appUser } = useSession();

  const {
    canAccessCalendar,
    canAccessContacts,
    canAccessDirectory,
    revokeGoogleAccess,
  } = useGoogleResourcesAccess();

  const { isVisible, onVisibilitySet, onVisibilityRemove } = useVisible();

  const handleDisconnect = () => {
    revokeGoogleAccess(appUser.user_id);
    dispatch(contactsApi.util.invalidateTags([ContactsTags.Contacts]));
  };

  return (
    <div className={styles.dataControlContent}>
      <p className={styles.dataControlSubtitle}>
        Remove all access (Calendar, Contacts, Directory)
      </p>
      <Button
        disabled={
          !canAccessCalendar && !canAccessContacts && !canAccessDirectory
        }
        onClick={onVisibilitySet}
        className="nj-settings-tab-content--disconnect-button"
      >
        <CloudSlash size={24} />
      </Button>

      <AccessModal
        open={isVisible}
        title="Disconnect Calendar, Contacts & Directory"
        submitTitle="Confirm disconnect"
        onSubmit={handleDisconnect}
        onClose={onVisibilityRemove}
      >
        <p className="nj-settings-tab-content--access-description">
          Are you sure you want to disconnect Calendar & Contacts?
        </p>

        <p className="nj-settings-tab-content--access-description">
          To proceed, click "Confirm disconnect" below.
        </p>
      </AccessModal>
    </div>
  );
};
