import { SVGProps } from 'react';

interface IconProps extends SVGProps<SVGSVGElement> {
  weight?: 'fill' | 'regular';
}

export const SentimentVeryDissatisfied = ({
  width = 24,
  height = 24,
  weight = 'regular',
}: IconProps) => {
  if (weight === 'fill') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 25 24"
        fill="none"
      >
        <mask
          id="mask0_2851_116933"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width={width}
          height={height}
        >
          <rect
            x="0.199951"
            width={width}
            height={height}
            fill="currentColor"
          />
        </mask>
        <g mask="url(#mask0_2851_116933)">
          <path
            d="M12.2 13.5C11.1565 13.5 10.2026 13.792 9.33845 14.376C8.47428 14.96 7.83712 15.7321 7.42695 16.6923H16.973C16.5628 15.7321 15.9256 14.96 15.0615 14.376C14.1973 13.792 13.2435 13.5 12.2 13.5ZM7.96145 11.7885L9.06145 10.7385L10.1115 11.7885L10.95 10.95L9.89995 9.9L10.95 8.8L10.1115 7.9615L9.06145 9.0115L7.96145 7.9615L7.12295 8.8L8.17295 9.9L7.12295 10.95L7.96145 11.7885ZM14.2885 11.7885L15.3385 10.7385L16.4385 11.7885L17.277 10.95L16.227 9.9L17.277 8.8L16.4385 7.9615L15.3385 9.0115L14.2885 7.9615L13.45 8.8L14.5 9.9L13.45 10.95L14.2885 11.7885ZM12.2017 21.5C10.8877 21.5 9.65262 21.2507 8.49645 20.752C7.34028 20.2533 6.33462 19.5766 5.47945 18.7218C4.62428 17.8669 3.9472 16.8617 3.4482 15.706C2.94937 14.5503 2.69995 13.3156 2.69995 12.0017C2.69995 10.6877 2.94928 9.45267 3.44795 8.2965C3.94662 7.14033 4.62337 6.13467 5.4782 5.2795C6.33303 4.42433 7.33828 3.74725 8.49395 3.24825C9.64962 2.74942 10.8844 2.5 12.1982 2.5C13.5122 2.5 14.7473 2.74933 15.9035 3.248C17.0596 3.74667 18.0653 4.42342 18.9205 5.27825C19.7756 6.13308 20.4527 7.13833 20.9517 8.294C21.4505 9.44967 21.7 10.6844 21.7 11.9983C21.7 13.3123 21.4506 14.5473 20.952 15.7035C20.4533 16.8597 19.7765 17.8653 18.9217 18.7205C18.0669 19.5757 17.0616 20.2528 15.906 20.7518C14.7503 21.2506 13.5155 21.5 12.2017 21.5Z"
            fill="currentColor"
          />
        </g>
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_2753_200104"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={width}
        height={height}
      >
        <rect width={width} height={height} fill="currentColor" />
      </mask>
      <g mask="url(#mask0_2753_200104)">
        <path
          d="M12 13.5C10.9565 13.5 10.0027 13.792 9.1385 14.376C8.27433 14.96 7.63717 15.7321 7.227 16.6923H16.773C16.3628 15.7321 15.7257 14.96 14.8615 14.376C13.9973 13.792 13.0435 13.5 12 13.5ZM7.7615 11.7885L8.8615 10.7385L9.9115 11.7885L10.75 10.95L9.7 9.9L10.75 8.8L9.9115 7.9615L8.8615 9.0115L7.7615 7.9615L6.923 8.8L7.973 9.9L6.923 10.95L7.7615 11.7885ZM14.0885 11.7885L15.1385 10.7385L16.2385 11.7885L17.077 10.95L16.027 9.9L17.077 8.8L16.2385 7.9615L15.1385 9.0115L14.0885 7.9615L13.25 8.8L14.3 9.9L13.25 10.95L14.0885 11.7885ZM12.0017 21.5C10.6877 21.5 9.45267 21.2507 8.2965 20.752C7.14033 20.2533 6.13467 19.5766 5.2795 18.7218C4.42433 17.8669 3.74725 16.8617 3.24825 15.706C2.74942 14.5503 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45267 3.248 8.2965C3.74667 7.14033 4.42342 6.13467 5.27825 5.2795C6.13308 4.42433 7.13833 3.74725 8.294 3.24825C9.44967 2.74942 10.6844 2.5 11.9983 2.5C13.3123 2.5 14.5473 2.74933 15.7035 3.248C16.8597 3.74667 17.8653 4.42342 18.7205 5.27825C19.5757 6.13308 20.2528 7.13833 20.7518 8.294C21.2506 9.44967 21.5 10.6844 21.5 11.9983C21.5 13.3123 21.2507 14.5473 20.752 15.7035C20.2533 16.8597 19.5766 17.8653 18.7218 18.7205C17.8669 19.5757 16.8617 20.2528 15.706 20.7518C14.5503 21.2506 13.3156 21.5 12.0017 21.5ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
