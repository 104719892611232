import { ImageCard } from 'src/types/models/ImageCard';
import { useContext, useMemo } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import {
  ImageGenSettingsDimensionGroups,
  ImageGenSettingsResolutions,
  ModalGalleryType,
} from 'src/types';
import { prepareGeneratedImages } from 'src/utils';
import './ThreadImageGenerationCard.scss';
import { ResearchMediaThumbnail } from '../ThreadResearchCard/components/ResearchMediaThumbnail';
import { ImageGenerationModels, SVG_SIZE_M } from 'src/constants';
import { useSession, useExternalModels } from 'src/hooks';
import { SlidersHorizontal, X } from '@phosphor-icons/react';
import { useCollapse } from 'react-collapsed';
import { ImageGenModifyPanel } from 'src/components/FlatAppearance/components/ImageGenModifyPanel';
import { ImageGenData } from 'src/types/models/ImageGenData';
import { EditImageUpsell } from 'src/v2/components/ThreadMessage/components/EditImageUpsell';
import { FeedbackRating } from 'src/v2/components/FeedbackRating';

interface ThreadImageGenerationCardProps {
  messageId?: string;
  imageCard: ImageCard;
  messageOriginalQuery?: string;
  messageCleanQuery?: string;
  isStreaming?: boolean;
}

export const ThreadImageGenerationCard = ({
  messageId,
  imageCard,
  messageOriginalQuery,
  messageCleanQuery,
  isStreaming,
}: ThreadImageGenerationCardProps) => {
  const { appUser, isLimitedTier } = useSession();
  const { images, watermarked_images } = imageCard;
  const { onChangeModalGalleryData } = useContext(SessionContext);

  const { imageExternalModels } = useExternalModels();

  const isLimitedEdited = useMemo(
    () => !!watermarked_images && isLimitedTier,
    [watermarked_images, isLimitedTier],
  );

  const imagesArray = useMemo(() => {
    if (!appUser.tier_id) {
      return [];
    }
    if (isLimitedEdited && watermarked_images) {
      // TODO(olha): it's a temporary workaround until BE returns proper model in response
      const imagesWithModels = Object.entries(watermarked_images).reduce(
        (acc, [key, item]) => {
          acc[key] = {
            ...item,
            model: 'alias/ninjatech/ninja-pix',
          };
          return acc;
        },
        {} as Record<string, ImageGenData>,
      );
      return prepareGeneratedImages({
        images: imagesWithModels,
        tierId: appUser.tier_id,
        withWatermark: true,
        imageExternalModels,
      });
    }
    if (!!images) {
      return prepareGeneratedImages({
        images,
        tierId: appUser.tier_id,
        imageExternalModels,
      });
    }
    return [];
  }, [
    images,
    watermarked_images,
    appUser.tier_id,
    isLimitedEdited,
    imageExternalModels,
  ]);

  const {
    isExpanded: isExpandedImageGenSettings,
    setExpanded,
    getCollapseProps,
  } = useCollapse({
    defaultExpanded: false,
  });

  const handleCloseImageGenSettings = () => {
    setExpanded(false);
  };

  const handleSetCurrentMedia = (index: number) => {
    onChangeModalGalleryData(
      true,
      ModalGalleryType.GENERATED_IMAGES,
      imagesArray,
      index,
      imageCard.task_id,
    );
  };

  const dimensionDescription = useMemo(() => {
    //TODO(olha): it's a temporary workaround until BE sends proper dimension values
    const currentImage = imagesArray[0];
    if (!currentImage) {
      return '';
    }

    const { size, dimension_description } = currentImage;

    if (!dimension_description?.group || !dimension_description?.option) {
      return size;
    }

    const { group, option } = dimension_description;

    const currentOption =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      appUser?.settings?.image_gen_settings?.dimensions?.groups?.[
        group as keyof ImageGenSettingsDimensionGroups
      ]?.[option] as ImageGenSettingsResolutions;

    return `${currentOption?.name || ''} ${currentOption?.aspect_ratio || ''}, ${size}`.trim();
  }, [imagesArray, appUser]);

  return (
    <div className="nj-thread-research-media nj-thread-image-generation-media-wrapper">
      <div className="nj-thread-image-generation-header-wrapper">
        <h6 className="nj-thread-image-generation-header-title">
          <span>Image generation</span>
          <span className="nj-thread-image-generation-header-title--caption">
            {imagesArray ? dimensionDescription : ''}
          </span>
        </h6>
        {!isStreaming &&
          (isExpandedImageGenSettings ? (
            <button
              type="button"
              className="nj-thread-image-generation-cancel-modify-button"
              onClick={handleCloseImageGenSettings}
            >
              <X size={SVG_SIZE_M} />
              <span>Cancel</span>
            </button>
          ) : !watermarked_images ? (
            <button
              type="button"
              className="nj-thread-image-generation-modify-button"
              onClick={() => {
                setExpanded(true);
              }}
            >
              <SlidersHorizontal size={SVG_SIZE_M} />
              <span>Modify</span>
            </button>
          ) : (
            <span className="nj-thread-image-generation--caption-italic">
              Edited
            </span>
          ))}
      </div>

      <div {...getCollapseProps()}>
        <ImageGenModifyPanel
          isOpen={isExpandedImageGenSettings}
          onClose={handleCloseImageGenSettings}
          messageOriginalQuery={messageOriginalQuery}
          messageCleanQuery={messageCleanQuery}
        />
      </div>

      <div className="nj-thread-image-generation-media-wrapper--image-list">
        {imagesArray.map((item, index) => (
          <ResearchMediaThumbnail
            key={item.id}
            thumbnail={item.url}
            title={item.title}
            isVideo={false}
            onClick={() => handleSetCurrentMedia(index)}
            icon={item.icon || undefined}
            status={item.status}
            isPixPro={item.source === ImageGenerationModels.PIX_PRO}
            isDisabled={item.isDisabled}
            isGenImage
            withWatermark={isLimitedEdited}
          />
        ))}
      </div>

      {isLimitedEdited && <EditImageUpsell />}

      <FeedbackRating
        title="Rate the images"
        messageId={messageId}
        isStreaming={!!isStreaming}
        operationType="message-level"
      />
    </div>
  );
};
