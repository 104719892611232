import { useState } from 'react';
import cn from 'classnames';
import { SVG_SIZE_L } from 'src/constants';
import { Button } from 'src/v2/commonComponents/Button';
import { useFeedbackRating, useVisible } from 'src/hooks';
import { Icon } from 'src/components/Icon';
import { Modal } from 'src/components/Modal';
import styles from './FeedbackRating.module.scss';
import { TextareaSimple } from 'src/v2/commonComponents/TextareaSimple';
import { FeedbackOperationType } from 'src/types';

const FEEDBACK_MAP = [
  {
    rating: 1,
    title: 'Incorrect',
    icon: 'sentiment-very-dissatisfied',
  },
  {
    rating: 2,
    title: 'Poor',
    icon: 'sentiment-dissatisfied',
  },
  {
    rating: 3,
    title: 'Fair',
    icon: 'sentiment-neutral',
  },
  {
    rating: 4,
    title: 'Good',
    icon: 'sentiment-satisfied',
  },
  {
    rating: 5,
    title: 'Superb',
    icon: 'sentiment-very-satisfied',
  },
];

interface FeedbackRatingProps {
  title?: string;
  isStreaming: boolean;
  messageId?: string;
  operationType: FeedbackOperationType;
  modelId?: string;
}

export const FeedbackRating = ({
  messageId,
  isStreaming,
  title = 'Rate the response',
  operationType,
  modelId,
}: FeedbackRatingProps) => {
  const { currentRating, updateFeedbackRating, addFeedbackDetails, isLoading } =
    useFeedbackRating({
      messageId,
      modelId,
      operationType,
    });

  const { isVisible, onVisibilityRemove, onVisibilitySet } = useVisible();
  const [value, setValue] = useState<string>('');

  if (!messageId || isStreaming) {
    return null;
  }

  const handleSubmit = async () => {
    await addFeedbackDetails(value);
    onVisibilityRemove();
    setValue('');
  };

  return (
    <>
      <div className={styles.root}>
        <div className={styles.content}>
          <p className={styles.title}>{title}</p>

          <Button
            onClick={onVisibilitySet}
            disabled={currentRating === undefined}
            color="link"
          >
            Tell us why?
          </Button>
        </div>

        <div className={styles.ratingWrapper}>
          {FEEDBACK_MAP.map(({ rating, icon, title }) => {
            const isSelected = currentRating === rating;

            return (
              <Button
                className={cn(styles.ratingButton, {
                  [styles.selected]: isSelected,
                })}
                key={rating}
                color="transparent"
                onClick={() =>
                  updateFeedbackRating({ rating, operationType, modelId })
                }
              >
                <Icon
                  weight={isSelected ? 'fill' : 'regular'}
                  type={icon}
                  size={SVG_SIZE_L}
                />
                <span className={styles.iconTitle}>{title}</span>
              </Button>
            );
          })}
        </div>
      </div>

      {isVisible && (
        <Modal open={isVisible} onClose={onVisibilityRemove}>
          <div className={styles.modalContainer}>
            <p className={styles.modalTitle}>Tell us why?</p>

            <TextareaSimple
              minRows={3}
              maxRows={3}
              value={value}
              placeholder="Write the feedback"
              onChange={setValue}
            />

            <Button
              onClick={handleSubmit}
              className={styles.submitButton}
              disabled={isLoading}
            >
              Submit
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};
