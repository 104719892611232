import { ReactNode, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { Prohibit } from '@phosphor-icons/react';
import { TaskState } from 'src/types';
import { StopTaskConfirmationModal } from 'src/pages/ManageTasksChatPage/components/StopTaskConfirmationModal';
import { useVisible } from 'src/hooks';
import { removeHashFromString } from 'src/utils';
import { SVG_SIZE_M } from 'src/constants';
import { Button } from 'src/v2/commonComponents/Button';
import styles from './StopTaskButton.module.scss';
import { FadeTransition } from 'src/animations/FadeTransition';

interface StopTaskButtonProps {
  taskId: string;
  taskState: TaskState;
  taskHash?: string;
  buttonName?: string | ReactNode;
  className?: string;
  onStop?: () => void;
}

export const StopTaskButton = ({
  taskId,
  taskState,
  taskHash,
  buttonName,
  className,
  onStop,
}: StopTaskButtonProps) => {
  const { isVisible, onVisibilityRemove, onVisibilitySet } = useVisible();

  const containerRef = useRef<HTMLButtonElement>(null);

  const isStopButtonVisible = useMemo(
    () =>
      taskState === TaskState.IN_PROGRESS || taskState === TaskState.BLOCKED,
    [taskState],
  );

  const taskName = removeHashFromString(taskHash || '');

  return (
    <>
      <FadeTransition inProp={isStopButtonVisible} nodeRef={containerRef}>
        <Button
          ref={containerRef}
          type="button"
          onClick={onVisibilitySet}
          color="transparent"
          className={classNames(styles.root, className)}
        >
          <Prohibit size={SVG_SIZE_M} />
          {buttonName}
        </Button>
      </FadeTransition>

      <StopTaskConfirmationModal
        isOpen={isVisible}
        onClose={onVisibilityRemove}
        taskId={taskId}
        taskName={taskName}
        onStop={onStop}
      />
    </>
  );
};
