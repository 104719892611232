import { useRef, useContext, useMemo } from 'react';
import styles from './Tooltip.module.scss';
import { XCircle } from '@phosphor-icons/react';
import { useLeftPanelState, useBreakpoint, useSession } from 'src/hooks';
import { useVisible, useConversationParams } from 'src/hooks';
import { CSSTransition } from 'react-transition-group';
import { ANIMATION_TIMEOUT } from 'src/constants';
import AvatarModeContext from 'src/contexts/AvatarModeContext';

interface TooltipProps {
  children: React.ReactNode;
}

// TODO(olha): Needs refactoring to avoid code duplicate from TiersAuthFooter
export const Tooltip = ({ children }: TooltipProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const SVG_SIZE = 24;
  const { isVisible, onVisibilityRemove } = useVisible(true);

  const { isFreeTier, isProTrialTier, isOpenTier, isStandardTier } =
    useSession();
  const { isTasksListExpanded } = useLeftPanelState();
  const { isLandingPage } = useConversationParams();

  const { isMobile } = useBreakpoint();
  const { isFullscreen } = useContext(AvatarModeContext);

  const handleClick = () => {
    onVisibilityRemove();
  };

  const display =
    isLandingPage && !isTasksListExpanded && isVisible && !isFullscreen;

  const title = useMemo(() => {
    if (isOpenTier) {
      return 'Sign Up For Free';
    }
    if (isFreeTier || isProTrialTier) {
      return 'Unlimited Tasks, Starting at $5/mo';
    }
    if (isStandardTier) {
      return 'Unlimited external AI models';
    }
    return '';
  }, [isOpenTier, isFreeTier, isProTrialTier, isStandardTier]);

  const description = useMemo(() => {
    if (isOpenTier) {
      return 'Get access to your own AI assistant for research, writing, coding, image generation, scheduling and so much more. No credit card required.';
    }
    if (isFreeTier || isProTrialTier) {
      return 'Enjoy unlimited daily tasks, custom image settings, and access to the best AI models';
    }
    if (isStandardTier) {
      return 'Get answers from external models from companies like OpenAI, Google, and Anthropic';
    }
  }, [isOpenTier, isFreeTier, isProTrialTier, isStandardTier]);

  return (
    <CSSTransition
      in={display}
      timeout={ANIMATION_TIMEOUT}
      classNames={isMobile ? 'nj-animate-vertical-appearance' : ''}
      unmountOnExit
      appear={display}
      nodeRef={containerRef}
    >
      <div ref={containerRef} className={styles.root}>
        <div className={styles.content}>
          <XCircle
            size={SVG_SIZE}
            weight="fill"
            className={styles.close}
            onClick={handleClick}
          />
          <div className={styles.titleContainer}>
            <h4 className={styles.title}>{title}</h4>
            {(isOpenTier || isProTrialTier) && (
              <span className={styles.tag}>Limited time</span>
            )}
          </div>
          <p className={styles.description}>{description}</p>

          {children}
        </div>
      </div>
    </CSSTransition>
  );
};
