import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Calendar } from '@phosphor-icons/react';
import { CalendarCard } from 'src/types';
import { handleOpenUserCalendar } from 'src/utils';
import { ThreadMessageContent } from '../ThreadMessageContent';
import { ScheduleDateButton } from './components/ScheduleDateButton';
import { ScheduleEventGroup } from './components/ScheduleEventGroup';
import { FeedbackRating } from 'src/v2/components/FeedbackRating';

const SVG_SIZE = 20;

interface ThreadScheduleCardProps {
  messageId?: string;
  calendarCard?: CalendarCard;
  summary?: string;
  details?: string;
}

export const ThreadScheduleCard = ({
  messageId,
  calendarCard,
  summary,
  details,
}: ThreadScheduleCardProps) => {
  const [activeDateKey, setActiveDateKey] = useState<string | null>(null);

  const sortedDateKeys = useMemo(
    () =>
      calendarCard?.dates
        ? Object.keys(calendarCard.dates).sort((a, b) =>
            dayjs(a).diff(dayjs(b)),
          )
        : [],
    [calendarCard],
  );

  const activeKey = activeDateKey || sortedDateKeys[0];

  if (!calendarCard?.dates && !details) {
    return null;
  }

  return (
    <div className="nj-thread-schedule-card">
      {details && <ThreadMessageContent content={details} />}

      {calendarCard?.dates && (
        <div className="nj-thread-schedule-card-form">
          <div className="nj-thread-schedule-card--header">
            <span className="nj-thread-schedule-card--summary">
              {summary || 'Schedule'}
            </span>

            <button
              onClick={handleOpenUserCalendar}
              className="nj-thread-schedule-card--link"
            >
              <Calendar size={SVG_SIZE} />
              <span>Calendar</span>
            </button>
          </div>

          <div className="nj-thread-schedule-card--date-panel">
            {sortedDateKeys.map((item) => (
              <ScheduleDateButton
                key={item}
                date={item}
                activeDateKey={activeKey}
                events={calendarCard.dates[activeKey]}
                onClick={setActiveDateKey}
              />
            ))}
          </div>

          <p className="nj-thread-schedule-card--current-date">
            {dayjs(activeKey).format('dddd, MMM D, YYYY')}
          </p>

          <ScheduleEventGroup
            dateKeys={sortedDateKeys}
            eventsByDate={calendarCard.dates}
            activeDateKey={activeKey}
          />
        </div>
      )}

      <FeedbackRating
        messageId={messageId}
        isStreaming={false}
        operationType="message-level"
      />
    </div>
  );
};
