/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum FeedbackType2 {
  MODEL_FEEDBACK = 'model_feedback',
}
