import { SVGProps } from 'react';

interface IconProps extends SVGProps<SVGSVGElement> {
  weight?: 'fill' | 'regular';
}

export const SentimentNeutral = ({
  width = 24,
  height = 24,
  weight = 'regular',
}: IconProps) => {
  if (weight === 'fill') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M620-520q25 0 42.5-17.5T680-580q0-25-17.5-42.5T620-640q-25 0-42.5 17.5T560-580q0 25 17.5 42.5T620-520Zm-280 0q25 0 42.5-17.5T400-580q0-25-17.5-42.5T340-640q-25 0-42.5 17.5T280-580q0 25 17.5 42.5T340-520Zm20 180h240v-60H360v60ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_2753_67071"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={width}
        height={height}
      >
        <rect width={width} height={height} fill="currentColor" />
      </mask>
      <g mask="url(#mask0_2753_67071)">
        <path
          d="M15.406 10.8077C15.77 10.8077 16.0786 10.6803 16.3318 10.4255C16.5849 10.1708 16.7115 9.86158 16.7115 9.49775C16.7115 9.13392 16.5842 8.82542 16.3295 8.57225C16.0748 8.31892 15.7655 8.19225 15.4015 8.19225C15.0377 8.19225 14.7292 8.31967 14.476 8.5745C14.2228 8.82917 14.0962 9.13842 14.0962 9.50225C14.0962 9.86608 14.2236 10.1746 14.4783 10.4277C14.7329 10.6811 15.0422 10.8077 15.406 10.8077ZM8.5985 10.8077C8.96233 10.8077 9.27083 10.6803 9.524 10.4255C9.77717 10.1708 9.90375 9.86158 9.90375 9.49775C9.90375 9.13392 9.77642 8.82542 9.52175 8.57225C9.26708 8.31892 8.95783 8.19225 8.594 8.19225C8.23 8.19225 7.92142 8.31967 7.66825 8.5745C7.41508 8.82917 7.2885 9.13842 7.2885 9.50225C7.2885 9.86608 7.41583 10.1746 7.6705 10.4277C7.92517 10.6811 8.2345 10.8077 8.5985 10.8077ZM9.077 15.3462H14.923V14.1538H9.077V15.3462ZM12.0017 21.5C10.6877 21.5 9.45267 21.2507 8.2965 20.752C7.14033 20.2533 6.13467 19.5766 5.2795 18.7218C4.42433 17.8669 3.74725 16.8617 3.24825 15.706C2.74942 14.5503 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45267 3.248 8.2965C3.74667 7.14033 4.42342 6.13467 5.27825 5.2795C6.13308 4.42433 7.13833 3.74725 8.294 3.24825C9.44967 2.74942 10.6844 2.5 11.9983 2.5C13.3123 2.5 14.5473 2.74933 15.7035 3.248C16.8597 3.74667 17.8653 4.42342 18.7205 5.27825C19.5757 6.13308 20.2528 7.13833 20.7518 8.294C21.2506 9.44967 21.5 10.6844 21.5 11.9983C21.5 13.3123 21.2507 14.5473 20.752 15.7035C20.2533 16.8597 19.5766 17.8653 18.7218 18.7205C17.8669 19.5757 16.8617 20.2528 15.706 20.7518C14.5503 21.2506 13.3156 21.5 12.0017 21.5ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
