/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum FeedbackType1 {
  MESSAGE_TOP_LEVEL = 'message_top_level',
}
