import {
  useSession,
  useSubscriptionInfo,
  useSubscriptionPlans,
  useTeamInfo,
  useVisible,
} from 'src/hooks';
import { ManageAccountContentPage } from '../ManageAccountContentPage/ManageAccountContentPage';
import { SubscriptionPlansForm } from '../SubscriptionPlansForm';
import { CancelSubscription } from '../CancelSubscription';
import './SubscriptionPlans.scss';
import { PaymentPlanInterval } from 'src/types';
import { UpdateMonthlyToAnnuallySection } from 'src/v2/components/UpdateMonthlyToAnnuallySection';
import { LabelEnterprise } from 'src/components/LabelEnterprise';
import { LeaveWorkspaceSection } from 'src/v2/components/LeaveWorkspaceSection';
import { ChangeSeatsSection } from 'src/v2/components/ChangeSeatsSection';
import { ChangeSeatsCheckout } from 'src/v2/components/ChangeSeatsCheckout';

export const SubscriptionPlans = () => {
  const { isSubscribedTier, appUser, isEnterpriseTier, isTeamsTier } =
    useSession();
  const { isMember, isAdmin, isOwner } = useTeamInfo();
  const {
    isVisible: IsCheckoutOpen,
    onVisibilitySet: onOpenCheckout,
    onVisibilityRemove: onCloseCheckout,
  } = useVisible(false);

  const {
    isVisible: IsChangeSeatsCheckoutOpen,
    onVisibilitySet: onOpenChangeSeatsCheckout,
    onVisibilityRemove: onCloseChangeSeatsCheckout,
  } = useVisible(false);

  const { plans } = useSubscriptionPlans({
    group: isTeamsTier ? 'teams' : 'individual',
  });

  const { subscriptionInfo } = useSubscriptionInfo();

  const annuallyPlanInfo = plans
    ? plans.filter(
        (item) =>
          item.plan_tier === (appUser.tier_id as string) &&
          item.period === PaymentPlanInterval.ANNUALLY,
      )[0]
    : undefined;

  return !isEnterpriseTier ? (
    <>
      <ManageAccountContentPage
        title="Subscription"
        errorMessage={
          subscriptionInfo?.processing_plan_change
            ? 'Payment processing in progress'
            : undefined
        }
      >
        <div className="nj-subscription-plans">
          <SubscriptionPlansForm onOpenCheckout={onOpenCheckout} />

          {isTeamsTier && !isMember && (
            <>
              <hr className="divider" />

              <ChangeSeatsSection
                onOpenChangeSeatsCheckout={onOpenChangeSeatsCheckout}
              />
            </>
          )}
          {isTeamsTier && !isOwner && (
            <>
              <hr className="divider" />

              <LeaveWorkspaceSection />
            </>
          )}

          {isSubscribedTier && !isMember && !isAdmin && (
            <>
              <hr className="divider" />

              <CancelSubscription />
            </>
          )}
        </div>
      </ManageAccountContentPage>
      <UpdateMonthlyToAnnuallySection
        isOpen={IsCheckoutOpen}
        onClose={onCloseCheckout}
        selectedPlan={annuallyPlanInfo}
      />
      <ChangeSeatsCheckout
        isOpen={IsChangeSeatsCheckoutOpen}
        onClose={onCloseChangeSeatsCheckout}
      />
    </>
  ) : (
    <ManageAccountContentPage title="Subscription">
      <div className="enterprise-root">
        <h4 className="subtitle">Your subscription</h4>
        <div className="plan">
          <p>Plan</p>
          <p className="nj-subscription-plans-form--value">
            <LabelEnterprise />
          </p>
        </div>
      </div>
    </ManageAccountContentPage>
  );
};
