import { ChangeEvent, useContext, useRef } from 'react';
import { Paperclip } from '@phosphor-icons/react';
import {
  AttachmentFileInputId,
  MAX_NUMBER_OF_FILES,
  SVG_SIZE_M,
} from 'src/constants';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  useAttachFile,
  useBreakpoint,
  useOutsideClick,
  useSession,
  useVisible,
} from 'src/hooks';
import ThreadInputBoxContext, {
  ThreadInputBoxContextType,
} from 'src/contexts/ThreadInputBoxContext';
import styles from './AddAttachmentButton.module.scss';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';
import { Button } from 'src/v2/commonComponents/Button';
import classNames from 'classnames';

interface AddAttachmentButtonProps {
  withTitle?: boolean;
  onClose?: () => void;
}

export const AddAttachmentButton = ({
  withTitle,
  onClose,
}: AddAttachmentButtonProps) => {
  const { handleUploadedFiles } = useAttachFile();
  const { isMobile } = useBreakpoint();
  const containerRef = useRef<HTMLDivElement>(null);
  const { promptLoading, threadInputBoxFiles, fileDataToSend } =
    useContext<ThreadInputBoxContextType>(ThreadInputBoxContext);
  const {
    isVisible: isVisibleTooltip,
    onVisibilitySet: onShowTooltip,
    onVisibilityRemove: onHideTooltip,
  } = useVisible();
  useOutsideClick(containerRef, onHideTooltip);

  const { isOpenTier } = useSession();

  const handleAttachClick = () => {
    if (isOpenTier) {
      onShowTooltip();
    } else {
      document.getElementById(AttachmentFileInputId)?.click();
    }
  };

  const handleFileSelect = async (event: ChangeEvent) => {
    const files = ((event.target as HTMLInputElement)?.files || []) as FileList;
    handleUploadedFiles(files);
    if (onClose) {
      onClose();
    }
  };

  const isDisabled =
    promptLoading ||
    fileDataToSend?.length > 0 ||
    (!!threadInputBoxFiles &&
      Object.keys(threadInputBoxFiles).length === MAX_NUMBER_OF_FILES);

  return (
    <div ref={containerRef} className={styles.root}>
      <Button
        disabled={isDisabled}
        onClick={handleAttachClick}
        data-tooltip-id="attachment-tooltip"
        data-tooltip-place={isMobile ? 'bottom-start' : 'top-start'}
        shape={withTitle ? 'regular' : 'round'}
        color="transparent"
        className={classNames({ [styles.extendedButton]: withTitle })}
      >
        <Paperclip size={SVG_SIZE_M} />

        {withTitle && 'Attach file(s)'}
      </Button>
      <input
        id={AttachmentFileInputId}
        type="file"
        accept=".txt,.docx,.pdf,.csv,.tsv,.png,.jpg,.jpeg,.json,.log,.tab,.js,.ts,.php,.html,.shtml,.htm,.htx,.htt,.acgi,.c,.h,.cpp,.c++,.cc,.f,.f77,.f90,.for,.jav,.java,.lsp,.p,.pas,.pl,.py,.sh,.zsh,.fish,.tcl,.tch,.hs,.lhs,.go,.lua,.apng,.bmp,.dib,.drle,.dpx,.fits,.fit,.fts,.g3,.gif,.ico,.jp2,.jpg2,.jfif,.pjpeg,.pjp,.jpm,.jpgm,.svg,.svgz,.svg.gz,.tif,.tiff,.tfx,.psd,.wbmp,.xif,.pcx,.webp,.wmf,.arw,.cr2,.crw,.dcr,.dng,.erf,.k25,.kdc,.mrw,.nef,.nrw,.orf,.pef,.raf,.raw,.sr2,.srf,.x3f,.xls,.xlsx,.pptx,.zip"
        onChange={handleFileSelect}
        className={styles.attachInput}
        multiple
      />

      <ReactTooltip
        clickable
        id="attachment-tooltip"
        disableStyleInjection
        noArrow={true}
        className="nj-attachment-tooltip"
        isOpen={isVisibleTooltip}
        offset={isMobile ? 0 : 20}
        imperativeModeOnly={true}
        openOnClick
        data-e2e="attachment-tooltip"
      >
        <span>You’ll need a sign-in account to add attachment</span>
        <GuestAuthLinks dataGTM="Chat-thread-attach-file-signup" />
      </ReactTooltip>
    </div>
  );
};
