import { useCallback, useEffect, useMemo } from 'react';
import { messageTabRegistryState, useAppSelector } from 'src/store';
import { MessageTabData, MessageTabs, TabRegistryState } from 'src/types';
import { useThreadActions } from './useThreadActions';

export const useMessageTab = ({
  messageId,
  tabsData,
}: {
  messageId?: string;
  tabsData: MessageTabData[];
}) => {
  const { updateMessageTabRegistry } = useThreadActions();

  const messageTabRegistry = useAppSelector(messageTabRegistryState);

  const getTabSRegistry = useCallback(() => {
    return messageId ? messageTabRegistry?.[messageId] || undefined : undefined;
  }, [messageId, messageTabRegistry]);

  const getTabRegistryData = useCallback(
    (tabName: string): TabRegistryState | undefined => {
      return messageId
        ? messageTabRegistry?.[messageId]?.[tabName] || undefined
        : undefined;
    },
    [messageId, messageTabRegistry],
  );

  useEffect(() => {
    if (!messageId) {
      return;
    }

    tabsData.forEach(({ tabName, content }) => {
      if (tabName === MessageTabs.NINJA_AGENT) {
        return;
      }

      const isTabRead = getTabRegistryData(tabName)?.isRead;

      if (isTabRead) {
        return;
      }

      if (!content) {
        updateMessageTabRegistry({ messageId, tabName, isRead: null });
      }

      if (!!content && isTabRead !== undefined) {
        updateMessageTabRegistry({ messageId, tabName, isRead: false });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsData, messageId]);

  const onTabClick = useCallback(
    (tabName: string) => {
      if (tabName === MessageTabs.NINJA_AGENT || !messageId) {
        return;
      }

      const tabContent = tabsData.find(
        (item) => item.tabName === tabName,
      )?.content;

      if (tabContent) {
        updateMessageTabRegistry({ messageId, tabName, isRead: true });
      }
    },
    [messageId, tabsData, updateMessageTabRegistry],
  );

  return useMemo(
    () => ({
      messageTabRegistry,
      getTabRegistryData,
      getTabSRegistry,
      onTabClick,
    }),
    [messageTabRegistry, getTabRegistryData, getTabSRegistry, onTabClick],
  );
};
