import { useCallback, useMemo } from 'react';
import { useGetUserByIdQuery } from 'src/store/services';
import { useSession } from 'src/hooks';
import { useUserData } from 'src/hooks';
import { ExternalModelSettings, ImageGenSettingsOption } from 'src/types';

export const useExternalModels = () => {
  const {
    appUser: { user_id },
  } = useSession();

  const { updateUserSettings } = useUserData();

  const { data } = useGetUserByIdQuery(user_id, {
    skip: !user_id,
  });

  const textExternalModels = useMemo(
    () => data?.settings?.external_models || {},
    [data],
  );
  const imageGenSettings = useMemo(
    () => data?.settings?.image_gen_settings || {},
    [data],
  );
  const imageExternalModels = useMemo(
    () => data?.settings?.image_gen_settings?.models || [],
    [data],
  );

  const updateExternalModels = useCallback(
    async (updatedSettings: {
      textExternalModels?: Partial<ExternalModelSettings>;
      imageExternalModels?: ImageGenSettingsOption[];
    }) => {
      await updateUserSettings({
        external_models: {
          ...textExternalModels,
          ...updatedSettings.textExternalModels,
        },
        image_gen_settings: {
          ...imageGenSettings,
          models: updatedSettings.imageExternalModels ?? imageExternalModels,
        },
      });
    },
    [
      updateUserSettings,
      textExternalModels,
      imageExternalModels,
      imageGenSettings,
    ],
  );

  return useMemo(
    () => ({
      textExternalModels,
      imageExternalModels,
      updateExternalModels,
    }),
    [textExternalModels, imageExternalModels, updateExternalModels],
  );
};
